<template>
  <div class="ps-form__content">

    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
       </div>
    </div>
<!--    <div class="form-group">-->
<!--      <label>Address<sup>*</sup> </label>-->
<!--      <div class="form-group__content">-->
<!--        <input class="form-control" v-model="form.street"  type="text" />-->
<!--        <small v-if="errors && errors.street" class="text-danger">-->
<!--          {{ errors.street[0] }}-->
<!--        </small>-->
<!--      </div>-->
<!--    </div>-->
    <div class="form-group" v-if="!loading">
      <label>Address</label>
      <!-- <place-form></place-form> -->
      <GmapAutocomplete
          class="form-control"
          v-if="!loadingForm"
          @place_changed="setPlace"
      />
    </div>

    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Email Address<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.email" type="email" />
        <small v-if="errors && errors.email" class="text-danger">
          {{ errors.email[0] }}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Country</label>
          <input class="form-control" type="text" v-model="form.country" placeholder="" />
          <small v-if="errors && errors.country" class="text-danger">
            {{ errors.country[0] }}
          </small>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>City/Town</label>
          <input class="form-control" type="text" v-model="form.city" placeholder="" />
          <small v-if="errors && errors.city" class="text-danger">
            {{ errors.city[0] }}
          </small>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Phone<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.phone_number" type="text" />
        <small v-if="errors && errors.phone_number" class="text-danger">
          {{ errors.phone_number[0] }}
        </small>
      </div>
    </div>
    <div class="form-check mb-4 p-4">
      <input class="form-check-input" name="shippingAddress" type="checkbox" id="new_address"
             v-model="form.is_default" />
      <label class="form-check-label pl-4" for="new_address">Default address?</label>
    </div>
    <div class="form-group">
      <button class="ps-btn ps-btn--fullwidth" @click.prevent="onSubmit()">
        {{ loading ? "Saving..." : "Save" }}
        <i v-if="!loading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="loading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GoogleMap from "../../../../components/GoogleMap";
import PlaceForm from "../../../../components/PlaceForm";
export default {
  name: "AddressForm",
  components: { GoogleMap, PlaceForm },
  props: {
    addressType: String,
    addressId: String
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        email: "",
        phone_number: "",
        street: '',
        country: '',
        city: '',
        is_default: false,
      },
      errors: null,
      message: null,
      isError: false,
      loading: false,
      currentPlace: {},
      loadingForm: false,
    }
  },
  created() {
    this.$loading(true);
     if (this.addressId) {
       axios.get('address/' + this.addressId).then((response) => {
          this.form = response.data
          this.form.is_default = response.data.is_default === 1;
       });
     }
    this.$loading(false);
  },
  methods: {
      setPlace(place) {
        this.currentPlace = place;
        console.log(place);
      },

      onSubmit() {
        const data = {...this.form, address_type: this.addressType.toLowerCase()};
        data['current_place'] = this.currentPlace;
        if (this.addressId) {
          this.updateAddress(data)
        } else {
          this.createAddress(data)
        }
      },

      updateAddress(data) {
        this.isLoading = true;
        axios.put('address/' + this.addressId, data).then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.message = 'Address successfully updated'
            this.$router.push({name: 'merchant_addresses'});
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Address could not be updated. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      },

      createAddress(data) {
        axios.post('address', data).then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.message = 'Address successfully created'
            this.$router.push({name: 'merchant_addresses'});
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Address could not be created. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      },

      getAddress() {
        axios.get(`address/${this.addressId}`).then((response) => {
          this.form = response.data
        });
      }
  }
}
</script>

<style scoped>

</style>